<template>
  <b-row>
    <b-col
      :sm="field.type === 'divider' ? 12 : field.cols ? field.cols : 6"
      v-for="field in fields"
      :key="field.key"
      :class="field.class"
    >
      <slot :name="field.key" :field="field">
        <div class="d-flex mt-2" v-if="field.type === 'divider'">
          <feather-icon size="19" :icon="field.icon" v-if="field.icon" />
          <h4 class="ml-50">{{ $t(field.label) }}</h4>
        </div>
        <b-form-group
          :label-for="field.key"
          :description="getDesciption(field)"
          v-if="field.type !== 'divider'"
          :class="field.class"
        >
          <validation-provider
            #default="{ errors }"
            :vid="field.key"
            :name="$t(field.label)"
            :rules="field.rules"
          >
            <label v-if="field.type !== 'checkbox' && !field.hideLabel"
              >{{ $t(field.label) }}
              <span class="text-muted" v-if="isOptional(field.rules)">
                - {{ $t('field.optional') }}
              </span>
            </label>
            <b-form-input
              v-if="field.type === 'text' && !field.placeholder"
              v-model="data[field.key]"
              :placeholder="$t(field.label)"
              :name="field.key"
              :disabled="field.disabled"
              @input="onChange"
              :class="errors.length > 0 ? 'is-invalid' : null"
              autocomplete="off"
            />
            <b-form-input
              v-if="field.type === 'text' && field.placeholder"
              v-model="data[field.key]"
              :placeholder="$t(field.label) + ' ' + $t(field.placeholder)"
              :name="field.key"
              :disabled="field.disabled"
              @input="onChange"
              :class="errors.length > 0 ? 'is-invalid' : null"
              autocomplete="off"
            />
            <n-time-input
              v-if="field.type === 'time'"
              v-model="data[field.key]"
              :id="field.key"
              :name="field.key"
              :placeholder="$t(field.label)"
              :errors="errors"
              :disabled="field.disabled"
              @input="onChange"
              :class="errors.length > 0 ? 'is-invalid' : null"
            ></n-time-input>
            <n-password-input
              v-if="field.type === 'password'"
              v-model="data[field.key]"
              :id="field.key"
              :name="field.key"
              :placeholder="$t(field.label)"
              :errors="errors"
              :disabled="field.disabled"
              @input="onChange"
              :class="errors.length > 0 ? 'is-invalid' : null"
            ></n-password-input>
            <n-currency-input
              v-if="field.type === 'currency'"
              v-model="data[field.key]"
              :id="field.key"
              :name="field.key"
              :placeholder="$t(field.label)"
              :errors="errors"
              :disabled="field.disabled"
              @input="onChange"
              :class="errors.length > 0 ? 'is-invalid' : null"
            ></n-currency-input>
            <n-tel-input
              v-if="field.type === 'tel'"
              v-model="data[field.key]"
              :id="field.key"
              :name="field.key"
              :placeholder="$t(field.label)"
              :errors="errors"
              :disabled="field.disabled"
              @input="onChange"
              :class="errors.length > 0 ? 'is-invalid' : null"
            ></n-tel-input>
            <b-form-radio-group
              v-if="field.type === 'radio'"
              :id="field.key"
              v-model="data[field.key]"
              :disabled="field.disabled"
              @change="onChange"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in field.options"
                :key="option.value"
                >{{ $t(option.text) }}</b-form-radio
              >
            </b-form-radio-group>
            <b-form-checkbox
              v-if="field.type === 'checkbox'"
              :id="field.key"
              v-model="data[field.key]"
              :name="field.key"
              :value="true"
              :unchecked-value="false"
              :disabled="field.disabled"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              {{ $t(field.label) }}
            </b-form-checkbox>
            <n-single-image-uploader
              v-if="field.type === 'single-image'"
              :pw="field.pw"
              :ph="field.ph"
              :fullWidth="field.fullWidth"
              :image="initValue[field.initKey]"
              v-model="data[field.key]"
              :readonly="field.disabled"
              @change="onChange"
              :class="errors.length > 0 ? 'is-invalid' : null"
            ></n-single-image-uploader>
            <b-form-textarea
              v-if="field.type === 'textarea'"
              v-model="data[field.key]"
              :id="field.key"
              :placeholder="$t(field.label)"
              :rows="field.rows ? field.rows : 2"
              :max-rows="field.maxRows ? field.maxRows : 6"
              :readonly="field.disabled"
              :disabled="field.disabled"
              @input="onChange"
              :class="errors.length > 0 ? 'is-invalid' : null"
            ></b-form-textarea>
            <n-multi-select
              v-if="field.type === 'NMultiSelection'"
              v-model="data[field.key]"
              :name="field.key"
              :init-options="data[field.key]"
              :options="field.options"
              :selection-key="field.selectionKey"
              :selection-label="field.selectionLabel"
              :readonly="field.disabled"
              @input="onChange"
              :class="errors.length > 0 ? 'is-invalid' : null"
            ></n-multi-select>
            <b-form-datepicker
              v-if="field.type === 'date'"
              :ref="field.key"
              v-model="data[field.key]"
              :disabled="field.disabled"
              :name="field.key"
              :class="errors.length > 0 ? 'is-invalid' : null"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }"
            />
            <n-async-multi-select
              v-if="field.type === 'nAsynMultiSelection'"
              v-model="data[field.key]"
              :name="field.key"
              :init-options="data[field.key]"
              :repository="field.repository"
              :selection-key="field.selectionKey"
              :selection-label="field.selectionLabel"
              :readonly="field.disabled"
              @input="onChange"
              :class="errors.length > 0 ? 'is-invalid' : null"
            ></n-async-multi-select>
            <n-async-single-select
              v-if="field.type === 'nAsynSingleSelection'"
              v-model="data[field.key]"
              :name="field.key"
              :init-options="data[field.key]"
              :repository="field.repository"
              :selection-key="field.selectionKey"
              :selection-label="field.selectionLabel"
              :readonly="field.disabled"
              @input="onChange"
              :class="errors.length > 0 ? 'is-invalid' : null"
            ></n-async-single-select>
            <n-single-select
              v-if="field.type === 'singleSelect'"
              v-model="data[field.key]"
              :options="field.options"
              :reduce="field.reduce"
              :clearable="field.clearable"
              :placeholder="field.placeholder"
            ></n-single-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </slot>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormRadio,
  BBadge,
  BRow,
  BCol,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue'
import NPasswordInput from '@/components/NPasswordInput'
import NAsyncMultiSelect from '@/components/NAsyncMultiSelect'
import NMultiSelect from '@/components/NMultiSelect'
import NAsyncSingleSelect from '@/components/NAsyncSingleSelect'
import NSingleImageUploader from '@/components/NSingleImageUploader'
import NCurrencyInput from '@/components/NCurrencyInput'
import NTelInput from '@/components/NTelInput'
import NSingleSelect from '@/components/NSingleSelect.vue'
import NTimeInput from './NTimeInput'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    NPasswordInput,
    BFormRadioGroup,
    BFormRadio,
    NSingleImageUploader,
    BBadge,
    BRow,
    BCol,
    BFormTextarea,
    NAsyncMultiSelect,
    NAsyncSingleSelect,
    BFormCheckbox,
    NCurrencyInput,
    NTelInput,
    NMultiSelect,
    NSingleSelect,
    NTimeInput,
    BFormDatepicker,
  },
  props: {
    value: {},
    initValue: {
      type: Object,
      default: function () {
        return {}
      },
    },
    fields: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {},
  watch: {
    data: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    initValue: function (newValue, oldValue) {
      this.data = {
        ...this.data,
        ...newValue,
      }
    },
  },
  mounted() {
    this.data = {
      ...this.value,
    }
  },
  methods: {
    setValue(value) {
      this.data = {
        ...value,
      }
    },
    getDesciption(field) {
      if (field.type === 'single-image') {
        return this.$t('general.recommendDimension', {
          ph: field.ph,
          pw: field.pw,
        })
      }
      return ''
    },
    isOptional(rules) {
      if (rules) {
        return !rules.includes('required')
      }
      return true
    },
    onChange() {
      this.$emit('input', this.data)
    },
  },
}
</script>

<style scoped></style>
